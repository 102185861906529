<template>
    <div class="container-fluid">

        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Account</h5>
                            <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                              <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Account" v-model="filters.account" />
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Status</h5>
                            <div class="wrap">
                                <ejs-dropdownlist cssClass="e-outline" :dataSource='statuses' :mode='true' v-model="filters.status" placeholder='Status'></ejs-dropdownlist>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="item && item.processes && item.processes.length" class="col-lg-1 col-md-1">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="edit" style="color: white; background-color: blue;" @click="bulkEdit">
                                <span class="e-btn-content">Bulk Edit</span>
                            </button>
                        </div>
                    </div>
<!--                    <div class="col-lg-1 col-md-1">-->
<!--                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">-->
<!--                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="edit" style="color: white; background-color: green;" @click="create">-->
<!--                                <span class="e-btn-content">Create</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="col-lg-1 col-md-1">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                    <div>
                        <ejs-button cssClass='e-info' v-on:click.native='onRefreshLayout'>Refresh Layout</ejs-button>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3">
            <div class="card-body">
                <div class="container-fluid">
                    <ejs-grid ref="grid" :dataSource="data" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings" :allowSelection="true" :selectionSettings="selectionSettings"
                              :rowSelected="onSelectionChange" :rowDeselected="onSelectionChange">
                        <e-columns>
                            <e-column type='checkbox' :allowFiltering='false' :allowSorting='false' width='60'></e-column>
                            <e-column field="created_ts" headerText="Created Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                            <e-column field="username" headerText="user" text-Align="Left"></e-column>
                            <e-column field='isp' headerText='ISP' textAlign='Left'></e-column>
                            <e-column field="status" headerText="Status" text-Align="Left"></e-column>
                            <e-column field="modified_ts" headerText="Last Modified" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                            <e-column :template="actionTemplate"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>

        <EWEdit :item="item" :type="type" @refresh="filter" />
    </div>
</template>

<script>

import {GridPlugin, Page, Sort} from '@syncfusion/ej2-vue-grids';
import Vue from "vue";
import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
Vue.use(GridPlugin);

import EWEdit from './editProcess';
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import swal from "sweetalert";

Vue.use(DropDownListPlugin);

let Bus = new Vue();

export default {
    name: "EWProcess",
    provide: {
        grid: [Page, Sort]
    },
    props: ["user",'userInfo'],
    data: function () {
        return {
            item:{exclusionList:[],deleteList:[]},
            type: 'create',
            filters: {
                account: '',
                status: ''
            },
            statuses: [
                "active",
                "disabled"
            ],
            selectionSettings: { type: 'Multiple', checkboxOnly: true },
            pageSettings: { pageSize: 50, pageSizes: [50, 100, 500] },
            data: new DataManager({
                url: `${this.$root.serverUrl}/admin/email-warmer/processes`,
                adaptor: new WebApiAdaptor(),
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            }),
            actionTemplate: function () {
                return {
                    template: Vue.component('tosTemplate', {
                            template: `<button @click="edit" class='btn btn-primary btn-sm'>
                                          <span class="fa fa-pencil" title="View"></span>
                                        </button>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                edit() {
                                    Bus.$emit("edit", this.data);
                                }
                            }
                        }
                    )
                }
            }
        };
    },
    computed: {},
    components: { EWEdit },
    created: function () {
        this.$root.preloader = false;

        Bus.$on("edit", (data) => {

            if(data.exclusionList == undefined || data.exclusionList == null){
                data.exclusionList = ['noreply', 'no-reply', 'prime', 'amazon', 'ebay', 'tiktok', 'facbook', 'twitter', 'instagram', 'linked']
            }
            if(data.deleteList == undefined || data.deleteList == null){
                data.deleteList = ['noreply', 'no-reply', 'prime', 'amazon', 'ebay', 'tiktok', 'facbook', 'twitter', 'instagram']
            }
            this.item = data;
            this.item.processes = [data._id];
            delete this.item._id;

            this.type = 'edit'

            window.$('#myEditProcessModal').modal('show');
        })
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        onRefreshLayout () {
          this.$refs.grid.ej2Instances.refresh();

        },
        create() {
            this.item = {exclusionList:[],deleteList:[]}
            this.item.rules = [];
            this.item.replys = [];
            this.item.forwardings = [];
            this.type = 'create'
            window.$('#myEditProcessModal').modal('show');
        },
        bulkEdit() {
            this.item.rules = [];
            this.item.replys = [];
            this.item.forwardings = [];
            this.type = 'bulk'
            window.$('#myEditProcessModal').modal('show');
        },
        onSelectionChange() {
            this.item = {};
            this.item.processes = []
            const selectedRecords = this.$refs.grid.ej2Instances.getSelectedRecords();
            for (let i = 0; i < selectedRecords.length; i++) {
                this.item.processes.push(selectedRecords[i]._id)
            }
        },
        filter() {
            let request = {filters: {}};
            if (this.filters.status) request.filters.status = this.filters.status;
            if (this.filters.account) request.account = this.filters.account;

            let query = [];
            for (const key in request) {
                if (request.hasOwnProperty(key)) {
                    if (request[key]) {
                        query.push(`${key}=${typeof request[key] === 'object' ? JSON.stringify(request[key]) : request[key]}`);
                    }
                }
            }

            this.loading = true;

            this.$nextTick(async () => {
                try {
                    this.data = await new DataManager({
                        url: `${this.$root.serverUrl}/admin/email-warmer/processes?${query.join("&")}`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                } catch (err) {
                    swal({ title: "Oops", text: err.message, icon: "error" });
                } finally {
                    this.loading = false;
                }
            })
        }
    }
};
</script>

<style>
    @import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
    @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
    @import '../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
</style>
