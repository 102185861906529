<template>
    <div class="container-fluid">

        <div class="card p-4 pb-5 mt-5">
            <h2 class="border-bottom pb-2 mb-5">
                <i class="fa fa-bullseye"></i> Inbox Enhanced Process Configuration
            </h2>
            <div class="row justify-content-end p-3">
                <div class="col-auto ml-auto">
                    <button type="button" @click="editPage" class="btn btn btn-sm ml-auto text-success">Edit</button>
                </div>
                <div class="col-auto">
                    <button type="button" @click="cancelPage" :disabled="!edit" class="btn btn btn-sm text-danger">Cancel</button>
                </div>
            </div>
<!--            <hr>-->

        <ejs-grid :dataSource='data' :editSettings='editSettings' :toolbar='toolbar' height='273px'  v-show="!edit">
            <e-columns>
                <e-column field='username' headerText='Username' textAlign='Left' width='100'  :validationRules='customerIDRules' ></e-column>
                <e-column field='password' headerText='Password' textAlign='Left' width='100' type='password'  :validationRules='customerIDRules'></e-column>
                <e-column field='isp' headerText='ISP' textAlign='Left' width='100'  :validationRules='customerIDRules'></e-column>
                <e-column field='faInformation' headerText='2FA Information' textAlign='Left' width='100'  :validationRules='customerIDRules'></e-column>
                <e-column field='firstName' headerText='First Name' textAlign='Left' width='100'  :validationRules='customerIDRules'></e-column>
                <e-column field='lastName' headerText='Last Name' textAlign='Left' width='100'  :validationRules='customerIDRules'></e-column>
                <e-column field='lastLogin' headerText='Last Login' textAlign='Left' width='150'  format='yMd' type='date ' :allowEditing='false'></e-column>
            </e-columns>


        </ejs-grid>

            <editPorcess v-show="edit"/>

        </div>

    </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import {DataManager, UrlAdaptor} from '@syncfusion/ej2-data';
// import { data } from './datasource.js';
import editPorcess from '../components/emailWarmer/processes.vue';

Vue.use(GridPlugin);

export default {
    data() {
        return {
            data: new DataManager({
                url: `${this.$root.serverUrl}/admin/emailWarmer/EmailConfig`,
                updateUrl: `${this.$root.serverUrl}/admin/emailWarmer/EmailConfig/update`,
                insertUrl: `${this.$root.serverUrl}/admin/emailWarmer/EmailConfig/insert`,
                removeUrl: `${this.$root.serverUrl}/admin/emailWarmer/EmailConfig/remove`,
                // batchUrl: `${this.$root.serverUrl}/admin/emailWarmer/EmailConfig/batchupdate`,
                adaptor: new UrlAdaptor,
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            }),
            edit:false,
            editSettings: { showConfirmDialog: true, showDeleteConfirmDialog: true, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' },
            toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
            customerIDRules: { required: true, minLength: 3 },
        };
    },
    created() {
      this.$root.preloader = false;
    },
    provide: {
        grid: [Page, Edit, Toolbar]
    },
    methods: {
        editPage() {
            this.edit = true;
            this.$forceUpdate();
        },
        cancelPage() {
            this.edit = false;
            this.$forceUpdate();
        },
    },
    components: { editPorcess },

}
</script>
<style>
@import "/../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>




<!--<template>-->
<!--    <div class="container-fluid">-->
<!--        <div class="card p-4">-->
<!--            <h1 class="mb-4 text-center">User Login Information Form</h1>-->
<!--        <div class="container border p-4">-->
<!--            <form @submit.prevent="submitForm" class="needs-validation" novalidate>-->
<!--                <div class="mb-3">-->
<!--                    <label for="username" class="form-label">Username:</label>-->
<!--                    <input type="text" id="username" class="form-control" v-model="formData.username" required>-->
<!--                    <div class="invalid-feedback">-->
<!--                        Please enter a username.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <label for="password" class="form-label">Password:</label>-->
<!--                    <input type="password" id="password" class="form-control" v-model="formData.password" required>-->
<!--                    <div class="invalid-feedback">-->
<!--                        Please enter a password.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <label for="isp" class="form-label">ISP:</label>-->
<!--                    <select id="isp" class="form-select custom-dropdown" v-model="formData.isp">-->
<!--                        <option value="gmail">Gmail</option>-->
<!--                        <option value="o365">O365</option>-->
<!--                        <option value="live">Live</option>-->
<!--                        <option value="yahoo">Yahoo</option>-->
<!--                        <option value="other">Other</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <label for="2fa" class="form-label">2FA Information:</label>-->
<!--                    <input type="text" id="2fa" class="form-control" v-model="formData.twofa">-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <label for="firstName" class="form-label">First Name:</label>-->
<!--                    <input type="text" id="firstName" class="form-control" v-model="formData.firstName">-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <label for="lastName" class="form-label">Last Name:</label>-->
<!--                    <input type="text" id="lastName" class="form-control" v-model="formData.lastName">-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <label for="lastLogin" class="form-label">Last Login:</label>-->
<!--                    <input type="text" id="lastLogin" class="form-control" v-model="formData.lastLogin">-->
<!--                </div>-->
<!--                <div class="mb-3 text-center">-->
<!--                    <button type="submit" class="btn btn-primary text">Submit</button>-->
<!--                </div>-->
<!--            </form>-->

<!--            <div v-if="submitted" class="mt-3 alert alert-success " role="alert">-->
<!--                Form submitted successfully!-->
<!--            </div>-->
<!--        </div>-->
<!--        </div>-->


<!--    </div>-->
<!--</template>-->
<!--<script>-->

<!--export default {-->
<!--    name: "email-config",-->
<!--    data() {-->
<!--        return {-->
<!--                formData: {-->
<!--                    username: '',-->
<!--                    password: '',-->
<!--                    isp: 'gmail',-->
<!--                    twofa: '',-->
<!--                    firstName: '',-->
<!--                    lastName: '',-->
<!--                    lastLogin: ''-->
<!--                },-->
<!--            submitted: false-->
<!--        };-->
<!--    },-->
<!--    created() {-->
<!--        this.$root.preloader = false;-->
<!--    },-->
<!--    methods: {-->
<!--        submitForm() {-->
<!--            // Perform form submission logic here-->
<!--            console.log('Form submitted with data:', this.formData);-->
<!--            // Assuming successful submission, reset the form-->
<!--            this.formData = {-->
<!--                username: '',-->
<!--                password: '',-->
<!--                isp: 'gmail',-->
<!--                twofa: '',-->
<!--                firstName: '',-->
<!--                lastName: '',-->
<!--                lastLogin: ''-->
<!--            };-->
<!--            this.submitted = true;-->
<!--        },-->

<!--    }-->
<!--}-->
<!--</script>-->

<!--<style>-->
<!--    /* Custom CSS for dropdown styling */-->
<!--.custom-dropdown {-->
<!--    background-color: #f8f9fa;-->
<!--    border: 1px solid #ced4da;-->
<!--    border-radius: .25rem;-->
<!--}-->
<!--</style>-->
